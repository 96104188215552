@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin position_absolute($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  margin: auto;
}

@mixin position_fixed($top, $right, $bottom, $left) {
  position: fixed;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin flexbox($direction, $align, $justify) {
  display: flex;
  align-items: $align;
  flex-direction: $direction;
  justify-content: $justify;
  display: -webkit-flex;
  -webkit-align-items: $align;
  -webkit-flex-direction: $direction;
  -webkit-justify-content: $justify;
}

@mixin transform($transform) {
  transform: $transform;
  -webkit-transform: $transform;
}
