$max_width: 1000px;
$max_height: 750px;

// COLOR
$black:            #000;
$white:            #fff;
$white_alpha:      rgba(255,255,255,0.3);
$dark_gray:        #666;
$light_gray:       #aaa;

$red:              #eb3b2f;
$red_alpha:        rgba(235,59,47,0.7);
$pink:             #ef857d;
$pink_alpha:       rgba(239,133,125,0.7);
$light_pink:       #e9c9ce;
$light_pink_alpha: rgba(233,201,206,0.7);

$blue:             #00a1e9;
$dull_blue:        #619fc9;
$dull_blue_alpha:  rgba(97,159,201,0.7);

$light_yellow:     #efebaf;

$btn_yellow:       #f0eaad;
$btn_orange:       #c75b25;
$btn_blue:         #6eb5e9;
$btn_gold:         #b8860b;
$btn_pink:         $light_pink;
$btn_pink_alpha:   rgba(233,201,206,0.7);

// FONT
$font_basic: '游ゴシック Medium','Yu Gothic Medium',YuGothic,YuGothicM,'Hiragino Kaku Gothic ProN','Hiragino Kaku Gothic Pro',メイリオ,Meiryo,sans-serif;
$font_noto_sans: 'Noto Sans JP';
$font_josefin_sans: 'Josefin Sans', sans-serif;
