html,
body {
  margin: 0;
  padding: 0;
}

body {
  color: $black;
  font: 16px $font_basic;
}

.wrapper {
  @include size(100%, auto);
  @include flexbox(column, center, flex-start);
  margin: 50px auto;
}

.title {
  @include size(800px, auto);
  font: 32px $font_noto_sans;
  margin-bottom: 20px;
}

.error_msg {
  @include size(800px, 20px);
  margin-bottom: 20px;
  span {
    color: $red;
    font: 16px $font_noto_sans;
    &::after {
      content: 'もう一度やり直してください。';
    }
  }
}

.login_msg {
  color: $dark_gray;
  font: 20px $font_basic;
  margin: 10px;
}

.spinner {
  @include flexbox(row, center, flex-start);
  margin: 15px;
  .spinner-grow {
    @include size(2em, 2em);
    margin-right: .8em;
    margin-bottom: .3em;
  }
  .loading {
    color: $dark_gray;
    font: 24px $font_josefin_sans;
  }
}

.popover {
  font-size: 12px;
}

.modal_processing {
  @include size(250px, 250px);
  .modal-content {
    @include size(250px, 250px);
    @include flexbox(row, center, center);
  }
}

.modal_confirm {
  .modal-content {
    .modal-header,
    .modal-footer {
      border: none;
    }
  }
}

.download {
  @include size(800px, auto);
  button {
    @include size(320px, 3em);
  }
}

.btn_close {
  /* Reset default CSS*/
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  margin-right: .5em;
  &:hover {
    opacity: .5;
  }
  div {
    position: relative;
    width: 14px;
    height: 14px;
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      display: block;
      width: 14px;
      border-top: 1px solid $white;
    }
    &::before {
      transform: skewY(-45deg);
    }
    &::after {
      transform: skewY(45deg);
    }
  }
}

.upload {
  @include size(800px, auto);
  .upload_container {
    @include flexbox(row, center, flex-start);
    .dropzone {
      @include size(450px, 250px);
      border: 2px dashed #aaa;
      div {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        display: table;
        input {
          width: 100%;
          height: 100%;
        }
        .droparea_text {
          @include flexbox(row, center, center);
          .fa-file-csv {
            width: 100px;
            color: #777;
          }
          div {
            @include flexbox(column, center, center);
            width: 220px;
          }
        }
      }
    }
    .buttons {
      @include flexbox(column, flex-start, flex-start);
      margin-left: 30px;
      @include size(320px, 250px);
      button {
        @include size(100%, 3em)
      }
      &>:not(:nth-of-type(1)) {
        margin-top: 20px;
      }
      .btn_processing {
        span {
          margin-left: .5em;
        }
      }
    }
  }
  .upload_files {
    @include size(100%, auto);
    margin-top: 30px;
    .table_ {
      @include size(100%, auto);
      @include flexbox(row, center, flex-start);
      &>:nth-of-type(1) {
        @include flexbox(row, center, flex-start);
        @include size(28%, 3rem);
        padding-left: 1.5em;
      }
      &>:not(:nth-of-type(1)) {
        @include flexbox(row, center, center);
        @include size(12%, 3rem);
      }
    }
    .table_complete {
      &>:not(:nth-of-type(1)) {
        @include flexbox(row, center, flex-start);
        @include size(72%, 3rem);
      }
    }
    .header_ {
      color: $white;
      font-size: 15px;
      font-weight: bold;
      background-color: $dark_gray;
      div {
        &>:nth-of-type(2) {
          margin: 0 0 .2em .1em;
          .fa-question-circle{
            width: 1em;
            color: #aaa;
          }
        }
      }
    }
    .row_ {
      border-bottom: 1px solid #aaa;
      div {
        .upload_filename {
          font-size: 18px;
        }
      }
      &>:not(:nth-of-type(1)) {
        font-size: 1.2em;
      }
      .btn_close{
        div {
          &::before,
          &::after {
            border-top: 1px solid #666;
          }
        }
      }
    }
  }
  .error_detail {
    margin-top: 20px;
    padding: 10px;
    font-size: 12px;
    &>div {
      margin-top: 10px;
      .detail::before {
        content: "";
        width: .8em;
        height: .8em;
        display: inline-block;
        background-color: $dark_gray;
        border-radius: 20%;
        margin-right: .5em;
      }
    }
    &>:not(:nth-of-type(1)) {
      border-top: 1px solid $light_gray;
      padding-top: 10px;
    }
  }
}

